<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card class="full-card">
      <q-toolbar class="auto-height horizontal-line">
        <q-toolbar-title class="q-pa-sm"> スタート設定 </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section class="q-mr-md q-ml-md">
        <div class="max-height-dialog">
          <q-form ref="formRef" greedy>
            <div class="q-my-md" v-if="isTestMode">
              <ScheduleSetting
                v-model="value.test_detail.schedule"
                :startingPoint="value.test_detail.starting_point"
                :previousCampaign="previousCampaign"
                @update:onChangeStartingPoint="onChangeStartingPoint"
              />
              <div class="horizontal-dotted-line q-mt-md"></div>
              <div class="q-mt-md title">配信対象条件</div>
              <div class="q-mt-sm sub-title">
                このメッセージを配信対象とするユーザーの条件を設定してください（OR条件）
              </div>
              <q-expansion-item label="シナリオ" dense class="cus-expansion" v-model="isShowScenario">
                <div class="q-mb-sm">
                  <div>{{ $t('label.story.setting_unit') }}</div>
                  <div class="q-mt-sm">
                    <q-radio
                      size="sm"
                      round
                      outline
                      v-model="value.test_detail.unit_type"
                      val="message_qa_unit"
                      :label="$t('label.story.message_qa_unit')"
                      class="title"
                      style="width: 200px"
                    />
                    <q-radio
                      size="sm"
                      round
                      outline
                      v-model="value.test_detail.unit_type"
                      val="answer_unit"
                      :label="$t('label.story.answer_unit')"
                      class="title"
                      style="width: 200px"
                    />
                  </div>
                  <div>{{ $t('label.story.targeting') }}</div>
                  <div class="q-mt-sm">
                    <q-radio
                      size="sm"
                      round
                      outline
                      v-model="value.test_detail.delivery_target_type"
                      val="responding_users_type"
                      :label="$t('label.story.responding_users_type')"
                      class="title"
                      style="width: 200px"
                    />

                    <q-radio
                      size="sm"
                      round
                      outline
                      v-model="value.test_detail.delivery_target_type"
                      val="unanswered_users_type"
                      :label="$t('label.story.unanswered_users_type')"
                      class="title"
                      style="width: 200px"
                    />
                    <q-radio
                      size="sm"
                      round
                      outline
                      v-model="value.test_detail.delivery_target_type"
                      val="all"
                      :label="$t('label.all')"
                      class="title"
                    />
                  </div>
                  <UnReachedUsersSetting
                    v-model="value.test_detail.audience"
                    v-if="value.test_detail.unit_type == 'message_qa_unit'"
                    :isTestMode="isTestMode"
                    :scenarios="previousCampaign.test_detail?.scenarios"
                    :campaignId="previousCampaign._id"
                    @update:handleLoading="handleLoading"
                    ref="unReachedUsersTest"
                  />
                  <ScenarioAnswerOrNotSetting
                    v-model="value.test_detail.audience"
                    v-else
                    :isTestMode="isTestMode"
                    :scenarios="previousCampaign.test_detail?.scenarios"
                    :campaignId="previousCampaign._id"
                    @update:handleLoading="handleLoading"
                    ref="audienceOrNotTest"
                  />
                </div>
              </q-expansion-item>
              <q-expansion-item label="オーディエンスビルダー" dense class="cus-expansion">
                <div>
                  <AudienceOrNotSetting
                    v-model="value.test_detail.audience"
                    :isTestMode="isTestMode"
                    @update:handleLoading="handleLoading"
                    ref="audienceOrNotTest"
                  />
                </div>
              </q-expansion-item>
            </div>
            <div class="q-my-md" v-if="!isTestMode">
              <ScheduleSetting
                v-model="value.prod_detail.schedule"
                :startingPoint="value.prod_detail.starting_point"
                :previousCampaign="previousCampaign"
                @update:onChangeStartingPoint="onChangeStartingPoint"
              />
              <div class="horizontal-dotted-line q-mt-md"></div>
              <div class="q-mt-md title">配信対象条件</div>
              <div class="q-mt-sm sub-title">
                このメッセージを配信対象とするユーザーの条件を設定してください（OR条件）
              </div>
              <q-expansion-item label="シナリオ" dense class="cus-expansion" v-model="isShowScenario">
                <div class="q-mb-sm">
                  <div>{{ $t('label.story.setting_unit') }}</div>
                  <div class="q-mt-sm">
                    <q-radio
                      size="sm"
                      round
                      outline
                      v-model="value.prod_detail.unit_type"
                      val="message_qa_unit"
                      :label="$t('label.story.message_qa_unit')"
                      class="title"
                      style="width: 200px"
                    />

                    <q-radio
                      size="sm"
                      round
                      outline
                      v-model="value.prod_detail.unit_type"
                      val="answer_unit"
                      :label="$t('label.story.answer_unit')"
                      class="title"
                      style="width: 200px"
                    />
                  </div>
                  <div>{{ $t('label.story.targeting') }}</div>
                  <div class="q-mt-sm">
                    <q-radio
                      size="sm"
                      round
                      outline
                      v-model="value.prod_detail.delivery_target_type"
                      val="responding_users_type"
                      :label="$t('label.story.responding_users_type')"
                      class="title"
                      style="width: 200px"
                    />

                    <q-radio
                      size="sm"
                      round
                      outline
                      v-model="value.prod_detail.delivery_target_type"
                      val="unanswered_users_type"
                      :label="$t('label.story.unanswered_users_type')"
                      class="title"
                      style="width: 200px"
                    />
                    <q-radio
                      size="sm"
                      round
                      outline
                      v-model="value.prod_detail.delivery_target_type"
                      val="all"
                      :label="$t('label.all')"
                      class="title"
                    />
                  </div>

                  <UnReachedUsersSetting
                    v-model="value.prod_detail.audience"
                    v-if="value.prod_detail.unit_type == 'message_qa_unit'"
                    :isTestMode="isTestMode"
                    :scenarios="previousCampaign.prod_detail?.scenarios"
                    :campaignId="previousCampaign._id"
                    @update:handleLoading="handleLoading"
                    ref="unReachedUsersProd"
                  />
                  <ScenarioAnswerOrNotSetting
                    v-model="value.prod_detail.audience"
                    v-else
                    :isTestMode="isTestMode"
                    :scenarios="previousCampaign.prod_detail?.scenarios"
                    :campaignId="previousCampaign._id"
                    @update:handleLoading="handleLoading"
                    ref="audienceOrNotProd"
                  />
                </div>
              </q-expansion-item>
              <q-expansion-item label="オーディエンスビルダー" dense class="cus-expansion">
                <div>
                  <AudienceOrNotSetting
                    v-model="value.prod_detail.audience"
                    :isTestMode="isTestMode"
                    @update:handleLoading="handleLoading"
                    ref="audienceOrNotProd"
                  />
                </div>
              </q-expansion-item>
            </div>
          </q-form>
        </div>
        <div class="row no-wrap items-center q-mt-md">
          <q-space />
          <div class="q-gutter-sm">
            <q-btn no-caps flat class="btn-cancel" @click="onCancel">{{ $t('cancel') }} </q-btn>
            <q-btn no-caps class="btn-save" @click="onSubmit"> {{ $t('save') }} </q-btn>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang='ts'>
import { mixins, Options } from 'vue-class-component'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'
import { Prop } from 'vue-property-decorator'
import { IInitialCampaign, IPushCampaign } from '@/utils/types'
import UnReachedUsersSetting from '@/components/story/common/selectors/UnReachedUsersSetting.vue'
import ScheduleSetting from '@/components/story/common/selectors/ScheduleSetting.vue'
import ScenarioAnswerOrNotSetting from '@/components/story/common/selectors/ScenarioAnswerOrNotSetting.vue'
import AudienceOrNotSetting from '@/components/story/common/selectors/AudienceOrNotSetting.vue'
import { UNIT_TYPE } from '@/utils/constants'

@Options({
  components: { AudienceOrNotSetting, ScenarioAnswerOrNotSetting, UnReachedUsersSetting, ScheduleSetting },
  emits: ['update:modelValue', 'updateStartSettings'],
})
export default class StartPushFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  modelValue!: IPushCampaign

  @Prop({ default: '' })
  appId!: string

  @Prop({ default: false })
  isTestMode = false

  @Prop()
  previousCampaign!: IPushCampaign | IInitialCampaign

  isShowScenario = true

  get value(): IPushCampaign {
    return this.modelValue
  }

  set value(value: IPushCampaign) {
    this.$emit('update:modelValue', value)
  }

  validate() {
    let detail = this.value.prod_detail
    if (this.isTestMode) {
      detail = this.value.test_detail
    }

    if (!detail) {
      return false
    }

    if (detail?.unit_type === UNIT_TYPE.MESSAGE_QA_UNIT) {
      if (detail.audience?.reached_users && detail.audience?.reached_users?.length <= 0) {
        return false
      }
    } else {
      if (detail.audience?.card_or && detail.audience?.card_or?.length <= 0) {
        return false
      }
    }
    return true
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        if (!this.validate()) {
          this.$q.dialog({
            title: this.$t('error'),
            message: this.$t('messages.please_review_the_delivery_conditions'),
            persistent: true,
            html: true,
          })
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    this.$emit('updateStartSettings', this.value)
    this.visible = false
  }

  async onChangeStartingPoint(value) {
    if (this.isTestMode) {
      if (this.value.test_detail) {
        this.value.test_detail.starting_point = value
      }
    } else {
      if (this.value.prod_detail) {
        this.value.prod_detail.starting_point = value
      }
    }
    this.$emit('updateStartSettings', this.value)
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()
    })
  }

  handleLoading(val) {
    // this.loading = val
    console.log(val)
  }
}
</script>

<style scoped lang='scss'>
.horizontal-dotted-line {
  border-bottom: 1px dotted grey;
}
.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 23.17px;
  text-align: left;
}
.sub-title {
  font-size: 12px;
  font-weight: 400;
}
:deep(.cus-expansion .q-item) {
  padding-left: 0;
}
:deep(.cus-expansion .q-item__label) {
  font-size: 16px;
  font-weight: 400;
}
</style>
