
import { mixins, Options } from 'vue-class-component'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'
import { Prop } from 'vue-property-decorator'
import { IInitialCampaign, IPushCampaign } from '@/utils/types'
import UnReachedUsersSetting from '@/components/story/common/selectors/UnReachedUsersSetting.vue'
import ScheduleSetting from '@/components/story/common/selectors/ScheduleSetting.vue'
import ScenarioAnswerOrNotSetting from '@/components/story/common/selectors/ScenarioAnswerOrNotSetting.vue'
import AudienceOrNotSetting from '@/components/story/common/selectors/AudienceOrNotSetting.vue'
import { UNIT_TYPE } from '@/utils/constants'

@Options({
  components: { AudienceOrNotSetting, ScenarioAnswerOrNotSetting, UnReachedUsersSetting, ScheduleSetting },
  emits: ['update:modelValue', 'updateStartSettings'],
})
export default class StartPushFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  modelValue!: IPushCampaign

  @Prop({ default: '' })
  appId!: string

  @Prop({ default: false })
  isTestMode = false

  @Prop()
  previousCampaign!: IPushCampaign | IInitialCampaign

  isShowScenario = true

  get value(): IPushCampaign {
    return this.modelValue
  }

  set value(value: IPushCampaign) {
    this.$emit('update:modelValue', value)
  }

  validate() {
    let detail = this.value.prod_detail
    if (this.isTestMode) {
      detail = this.value.test_detail
    }

    if (!detail) {
      return false
    }

    if (detail?.unit_type === UNIT_TYPE.MESSAGE_QA_UNIT) {
      if (detail.audience?.reached_users && detail.audience?.reached_users?.length <= 0) {
        return false
      }
    } else {
      if (detail.audience?.card_or && detail.audience?.card_or?.length <= 0) {
        return false
      }
    }
    return true
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        if (!this.validate()) {
          this.$q.dialog({
            title: this.$t('error'),
            message: this.$t('messages.please_review_the_delivery_conditions'),
            persistent: true,
            html: true,
          })
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    this.$emit('updateStartSettings', this.value)
    this.visible = false
  }

  async onChangeStartingPoint(value) {
    if (this.isTestMode) {
      if (this.value.test_detail) {
        this.value.test_detail.starting_point = value
      }
    } else {
      if (this.value.prod_detail) {
        this.value.prod_detail.starting_point = value
      }
    }
    this.$emit('updateStartSettings', this.value)
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()
    })
  }

  handleLoading(val) {
    // this.loading = val
    console.log(val)
  }
}
