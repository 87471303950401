<template>
  <q-card style="width: 80%">
    <q-card-section>
      <div class="text-subtitle2 text-bold q-pl-md text-grey-8">Trigger</div>
      <div class="text-h5 text-bold q-pl-md">{{ $t('label.story.trigger_type') }}</div>
    </q-card-section>
    <q-card-section class="q-pt-none">
      <div class="flex justify-around">
        <div
          class="trigger-item"
          @click="changeTriggerType('popup_deliver')"
          :class="value == 'popup_deliver' ? 'popup-deliver-selected' : ''"
        >
          <div class="text-h6 text-bold" style="color: #3982c2">
            {{ $t('label.story.popup_deliver_trigger') }}
          </div>
          <q-btn unelevated padding="sm" icon="flag" class="trigger-icon flag-icon flag-icon__popup_deliver" />
          <div
            class="text-subtitle1 text-center text-grey-8"
            v-html="$t('label.story.popup_deliver_trigger_description')"
          ></div>
        </div>
        <div
          class="trigger-item"
          @click="changeTriggerType('audience')"
          :class="value == 'audience' ? 'audience-selected' : ''"
        >
          <div class="text-h6 text-pink text-bold">{{ $t('label.story.audience_trigger') }}</div>
          <q-btn unelevated padding="sm" color="pink-3" icon="forum" class="trigger-icon" />
          <div
            class="text-subtitle1 text-center text-grey-8"
            v-html="$t('label.story.audience_trigger_description')"
          ></div>
        </div>
        <div
          class="trigger-item"
          @click="changeTriggerType('all')"
          :class="value == 'all' ? 'all-selected' : ''"
        >
          <div class="text-h6 text-purple text-bold">{{ $t('label.story.all_trigger') }}</div>
          <q-btn unelevated padding="sm" color="purple-3" icon="send" class="trigger-icon" />
          <div class="text-subtitle1 text-center text-grey-8" v-html="$t('label.story.all_trigger_description')"></div>
        </div>
      </div>
      <div class="row no-wrap items-center q-mt-md">
        <q-space />
        <div class="q-gutter-sm">
          <q-btn no-caps outline color="white" text-color="grey" @click="onCancel">{{ $t('back') }}</q-btn>
          <q-btn no-caps color="grey" @click="onNext"> {{ $t('next') }} </q-btn>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue', 'update:stepValue'],
})
export default class ChooseTriggerType extends Vue {
  @Prop({ default: 'popup_deliver' })
  modelValue!: string

  get value() {
    return this.modelValue
  }

  set value(value: string) {
    this.$emit('update:modelValue', value)
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  changeTriggerType(type) {
    this.value = type
  }

  onCancel() {
    this.goto('stories', { app_id: this.appId })
  }

  onNext() {
    if (this.value === '') {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.please_select_trigger_type'),
        persistent: true,
        html: true,
      })
      return
    }
    this.$emit('update:stepValue')
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
</script>

<style scoped lang="scss">
.trigger-icon {
  border-radius: 10px;
}
:deep(.trigger-icon .q-icon) {
  font-size: 3.5em;
}
</style>
