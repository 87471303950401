
import { Options, mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { EScenarioResourceType, IPushAudience, IUserAnswer } from '@/utils/types'
import PushCampaignMixin from '@/components/push-campaign/mixins/PushCampaignMixin.vue'
import { ICard } from 'bot-flow-maker/src/types'
import { USER_ANSWER } from '@/store/actions'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class UnReachedUsersSetting extends mixins(PushCampaignMixin) {
  @Prop({})
  modelValue: IPushAudience = {
    unreached_users: [],
  }

  @Prop({})
  isTestMode!: boolean

  @Prop({})
  campaignId!: string

  @Prop()
  scenarios!: ICard[]

  reaches: ICard[] = []
  unReached: ICard[] = []

  @Watch('value', { immediate: true })
  async onRefresh() {
    this.unReached = this.value.unreached_users ?? []
    this.updateApi()
  }

  get value() {
    return this.modelValue
  }

  set value(value: IPushAudience) {
    this.$emit('update:modelValue', value)
  }

  iconName(row: ICard) {
    if (row.cardType === 'question') {
      return 'help_outline'
    } else if (row.cardType === 'goal') {
      return 'verified'
    }
    return 'message'
  }

  iconBg(row: ICard) {
    if (row.cardType === 'question') {
      return 'background: #ffc10d'
    } else if (row.cardType === 'goal') {
      return 'background: #6e9c9f'
    }
    return 'background: #8f969e'
  }

  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }

    const usersAnswers: IUserAnswer[] = await this.$store.dispatch(USER_ANSWER.GET_USER_ANSWER_BY_CAMPAIGN, {
      app_id: this.selectedAppId,
      campaign_id: this.campaignId,
      is_test_mode: this.isTestMode,
    })

    this.reaches = []
    if (this.scenarios) {
      const cards = this.scenarios.filter(
        (item) =>
          item.cardType === EScenarioResourceType.message ||
          item.cardType === EScenarioResourceType.question ||
          item.cardType === EScenarioResourceType.goal
      )
      for (let index = 0; index < cards.length; index++) {
        const item = cards[index]
        if (!this.checkExistItem(item.id)) {
          const a = usersAnswers.filter((p) => p.next_unique_id === item.uniqueId)
          const uniqueUserIds = new Set(a.map((p) => p.user_id)) // Create a set of unique user IDs
          const uniqueUserIdCount = uniqueUserIds.size // Count of unique user IDs
          item.totalUsers = uniqueUserIdCount
          this.reaches.push(item)
        }
      }

      this.value.reached_users = this.reaches
    }
  }

  checkExistItem(id: string) {
    const checkExist = this.value?.unreached_users?.find((item) => item.id === id)
    if (checkExist) {
      return true
    }
    return false
  }

  onAdd(row: ICard, index: number) {
    if (!this.value.unreached_users) {
      this.value.unreached_users = []
    }
    if (this.value.reached_users) {
      this.value.reached_users.splice(index, 1)
      this.value.unreached_users.push(row)
      this.onRefresh()
    }
  }

  onUnSelected(row: ICard, index: number) {
    if (!this.value.reached_users) {
      this.value.reached_users = []
    }
    if (this.value.unreached_users) {
      this.value.unreached_users.splice(index, 1)
      this.value.reached_users.push(row)
      this.onRefresh()
    }
  }
}
