
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { IPushCampaign, IStory } from '@/utils/types'
import { maska } from 'maska'
import AudienceOrNotSetting from '@/components/story/common/selectors/AudienceOrNotSetting.vue'
import cloneDeep from 'lodash/cloneDeep'
import CLoading from '@/components/common/ui/CLoading.vue'
import FrequencyCapSetting from '@/components/story/common/selectors/FrequencyCapSetting.vue'
import PushScheduleSetting from '@/components/story/common/selectors/PushScheduleSetting.vue'

@Options({
  components: { PushScheduleSetting, FrequencyCapSetting, CLoading, AudienceOrNotSetting },
  directives: { maska },
  emits: ['update:modelValue', 'update:onSave', 'update:onNextStep'],
})
export default class AudienceBuilder extends Vue {
  @Prop({})
  modelValue!: IStory

  @Prop({})
  isTestMode!: boolean

  form: IPushCampaign = {
    _id: '',
    title: '',
    app_id: '',
    delivered: 0,
    is_active: false,
    is_test_mode: false,
    audience_size: 0,
    prod_detail: {
      direction_type: 1,
      audience: {
        audience_or: [],
        audience_not: [],
      },

      frequency: {
        active: false,
        time_per_user: 1,
      },
      schedule: {
        schedule_type: '',
        schedule_by_type: '',
        delay_day: 0,
        day_of_week: 0,
        day_of_month: 1,
        date: '',
        time: '',
      },
      scenarios: [],
    },
    test_detail: {
      direction_type: 1,
      audience: {
        audience_or: [],
        audience_not: [],
      },

      frequency: {
        active: false,
        time_per_user: 1,
      },
      schedule: {
        schedule_type: '',
        schedule_by_type: '',
        delay_day: 0,
        day_of_week: 0,
        day_of_month: 1,
        date: '',
        time: '',
      },
      scenarios: [],
    },
  }

  loading = false

  get story() {
    return this.modelValue
  }

  set story(value: IStory) {
    this.$emit('update:modelValue', value)
  }

  @Watch('isTestMode', { immediate: true })
  async onChangeTestMode() {
    if (this.story.push) {
      this.form = cloneDeep(this.story.push)
    }
  }

  validate(isSave = false) {
    this.onSubmit(isSave)
  }

  async onSubmit(isSave = false) {
    this.form.is_test_mode = this.story.is_test_mode
    if (this.story.pushes) {
      this.story.pushes[0] = this.form
    }

    if (isSave) {
      this.$emit('update:onSave')
    } else {
      this.$emit('update:onNextStep')
    }
  }

  handleLoading(val) {
    this.loading = val
  }

  created() {
    this.onChangeTestMode()
  }
}
