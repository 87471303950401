<template>
  <AudienceBuilder
    ref="audienceBuilder"
    v-if="currentStep === 2"
    v-model="form"
    :isTestMode="isTestMode"
    @update:onSave="onSave"
    @update:onNextStep="onNextStep"
  ></AudienceBuilder>
  <Push
    v-if="currentStep === 4"
    ref="storyPush"
    v-model="form"
    :isTestMode="isTestMode"
    :selected-push-index="selectedPushIndex"
    :isOpenStartSettings="openStartSettings"
    :isStoryFirstPush="selectedPushIndex === 0 ? true : false"
    :isCopy="isCopy"
    @update:onSave="onSave"
    @update:openStartSettings="(value) => (openStartSettings = value)"
  ></Push>
</template>

<script lang='ts'>
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import { IStory } from '@/utils/types'
import AudienceBuilder from '@/components/story/trigger/audience/AudienceBuilder.vue'
import Push from '@/components/story/trigger/popup-deliver/Push.vue'

@Options({
  components: { Push, AudienceBuilder },
  directives: { maska },
  emits: ['update:modelValue', 'update:stepValue', 'update:onSave', 'update:onNextStep', 'update:openStartSettings'],
})
export default class Audience extends mixins(BaseFormMixin) {
  @Prop({})
  modelValue!: IStory

  @Prop()
  step!: number

  @Prop()
  selectedPushIndex!: number

  @Prop({})
  isTestMode!: boolean

  @Prop()
  isOpenStartSettings!: boolean

  @Prop({})
  isCopy!: boolean

  get form() {
    return this.modelValue
  }

  set form(value: IStory) {
    this.$emit('update:modelValue', value)
  }

  get currentStep() {
    return this.step
  }

  set currentStep(value: number) {
    this.$emit('update:stepValue', value)
  }

  get openStartSettings() {
    return this.isOpenStartSettings
  }

  set openStartSettings(value: boolean) {
    this.$emit('update:openStartSettings', value)
  }

  validate(isSave = false) {
    console.log(this.currentStep, 'currentStep')
    if (this.currentStep === 2) {
      this.$refs.audienceBuilder.validate(isSave)
    }
    if (this.currentStep === 4) {
      this.$refs.storyPush.validate(isSave)
    }
  }

  onSave() {
    this.$emit('update:onSave')
  }

  onNextStep() {
    this.$emit('update:onNextStep')
  }
}
</script>

<style scoped lang='scss'>
</style>
