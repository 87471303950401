
import { Options, mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { constant } from '@/utils/constants'
import { IAudience, IAudienceOrNot, IPushAudience } from '@/utils/types'
import { ACTION_AUDIENCE } from '@/store/actions'
import PushCampaignMixin from '@/components/push-campaign/mixins/PushCampaignMixin.vue'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue', 'update:handleLoading'],
})
export default class AudienceOrNotSetting extends mixins(PushCampaignMixin) {
  @Prop({})
  modelValue: IPushAudience = {
    audience_or: [],
    audience_not: [],
  }

  @Prop({})
  isTestMode!: boolean

  // errorMessage = ''
  totalAudienceProd = 0
  allProd!: IAudience
  audienceOr: IAudienceOrNot[] = []
  audienceNot: IAudienceOrNot[] = []

  audiences: IAudience[] = []
  pagination = {
    sortBy: '',
    descending: false,
    page: 1,
    rowsPerPage: constant.ROW_PER_PAGE,
    rowsNumber: 10,
  }

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  async getAudienceOr() {
    this.audienceOr = []
    if (this.value.audience_or) {
      for (let index = 0; index < this.value.audience_or.length; index++) {
        const element = this.value.audience_or[index]
        if (element._id === constant.DEFAULT_ALL_USER_AUDIENCE_ID) {
          if (this.allProd) {
            this.audienceOr.push({
              _id: this.allProd._id,
              title: this.allProd.title,
              audience_size: this.allProd.audience_size,
            })
          }
        } else {
          let result = this.audiences.find((or) => or._id === element._id)
          if (!result) {
            result = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_ITEM, element._id)
          }

          if (result) {
            this.audienceOr.push({
              _id: element._id,
              title: result.title,
              audience_size: result.audience_size,
            })
          }
        }
      }
    }
  }

  async getAudienceNot() {
    this.audienceNot = []
    if (this.value.audience_not) {
      for (let index = 0; index < this.value.audience_not.length; index++) {
        const element = this.value.audience_not[index]
        if (element._id === constant.DEFAULT_ALL_USER_AUDIENCE_ID) {
          if (this.allProd) {
            this.audienceNot.push({
              _id: this.allProd._id,
              title: this.allProd.title,
              audience_size: this.allProd.audience_size,
            })
          }
        } else {
          let result = this.audiences.find((not) => not._id === element._id)
          if (!result) {
            result = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_ITEM, element._id)
          }

          if (result) {
            this.audienceNot.push({
              _id: element._id,
              title: result.title,
              audience_size: result.audience_size,
            })
          }
        }
      }
    }
  }

  get value() {
    return this.modelValue
  }

  set value(value: IPushAudience) {
    this.$emit('update:modelValue', value)
  }

  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }
    this.$emit('update:handleLoading', true)

    this.audiences = []
    const result = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_USER_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
    })
    // eslint-disable-next-line
    if (result && result['count_active_users'] > 0) {
      this.allProd = {
        _id: constant.DEFAULT_ALL_USER_AUDIENCE_ID,
        app_id: this.selectedAppId,
        title: this.$t('label.all_user_audience_id'),
        // eslint-disable-next-line
        audience_size: result['count_active_users'],
        filter_condition: {
          answers: {},
          date_range: {
            from: '',
            to: '',
          },
        },
      }
      // eslint-disable-next-line
      this.totalAudienceProd = result['total_audiences']
    }

    // get initial data from server (1st page)
    await this.$refs.audienceDataRef.requestServerInteraction()
    await this.getAudienceOr()
    await this.getAudienceNot()
    return result
  }

  onDisabledOr(id: string) {
    const checkExist = this.value?.audience_or?.find((or) => or._id === id)
    if (checkExist) {
      return true
    }
  }

  onDisabledNot(id: string) {
    const checkExist = this.value?.audience_not?.find((or) => or._id === id)
    if (checkExist) {
      return true
    }
  }

  async onOr(row: IAudience) {
    console.log(this.value)
    if (!this.value.audience_or) {
      this.value.audience_or = []
    }
    this.value.audience_or.push(row)
    await this.getAudienceOr()
  }

  async onNot(row: IAudience) {
    console.log(this.value)
    if (!this.value.audience_not) {
      this.value.audience_not = []
    }
    this.value.audience_not.push(row)
    await this.getAudienceNot()
  }

  async onUnSelectedOr(index: number) {
    if (this.value.audience_or) {
      this.value.audience_or.splice(index, 1)
      await this.getAudienceOr()
    }
  }

  async onUnSelectedNot(index: number) {
    if (this.value.audience_not) {
      this.value.audience_not.splice(index, 1)
      await this.getAudienceNot()
    }
  }

  async onRequest(props) {
    this.audiences = []
    const { page, rowsPerPage, sortBy, descending } = props.pagination
    const filter = props.filter
    this.$emit('update:handleLoading', true)
    const fetchCount = rowsPerPage === 0 ? this.totalAudienceProd : rowsPerPage
    const startRow = (page - 1) * rowsPerPage
    const returnedData: [] = await this.fetchFromServer(
      startRow,
      fetchCount,
      filter,
      sortBy,
      descending,
      this.isTestMode
    )
    if (page === 1) {
      this.testAudiences = []
      if (this.allProd && this.allProd.audience_size && this.allProd.audience_size > 0) {
        this.audiences.push(this.allProd)
      }

      this.audiences = this.audiences.concat(returnedData)
    } else {
      this.audiences.splice(0, this.audiences.length, ...returnedData)
    }

    this.pagination.rowsNumber = this.totalAudienceProd
    this.pagination.page = page
    this.pagination.rowsPerPage = rowsPerPage
    this.pagination.sortBy = sortBy
    this.pagination.descending = descending
    this.$emit('update:handleLoading', false)
  }

  async fetchFromServer(startRow, count, filter, sortBy, descending, isTestMode) {
    const items = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_SIMPLE_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: isTestMode,
      is_include_data: false,
      filter: {
        start_row: startRow,
        rows_number: count,
        filter: filter,
        sort_by: sortBy,
        descending: descending,
      },
    })
    console.log('fetchFromServer', items)
    return items
  }
}
