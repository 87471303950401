
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import { IStory } from '@/utils/types'
import StartSettingBtnSelector from '@/components/story/common/selectors/StartSettingBtnSelector.vue'

@Options({
  components: { StartSettingBtnSelector },
  directives: { maska },
  emits: ['update:modelValue', 'update:selectedPushIndex', 'nextStep', 'deletePush', 'openStartSettings'],
})
export default class Stepper extends Vue {
  @Prop()
  modelValue!: number

  @Prop()
  form!: IStory

  @Prop()
  selectedPushIndex!: number

  @Prop({ default: false })
  isTestMode = false

  get value() {
    return this.modelValue
  }

  set value(value: number) {
    this.$emit('update:modelValue', value)
  }

  get pushIndex() {
    return this.selectedPushIndex
  }

  set pushIndex(value: number) {
    this.$emit('update:selectedPushIndex', value)
  }

  nextStep() {
    this.$emit('nextStep')
  }

  selectPush(index: number) {
    this.value = 4
    this.pushIndex = index
  }

  deletePush(index: number) {
    this.$emit('deletePush', index)
  }

  openStartSettings(index) {
    this.value = 4
    this.pushIndex = index
    this.$emit('openStartSettings')
  }
}
