
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IPushCampaignDetail, IStory } from '@/utils/types'
import { STARTING_POINT_TYPE, TRIGGER_TYPE } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: ['openStartSettings'],
})
export default class StartSettingBtnSelector extends Vue {
  @Prop()
  detail!: IPushCampaignDetail

  @Prop()
  form!: IStory

  @Prop()
  index!: number

  getpreviousCampaignName(index) {
    if (this.form.trigger_type === TRIGGER_TYPE.POPUP_DELIVER) {
      if (index === 0) {
        return this.form?.initial?.title
      } else {
        if (this.form?.pushes) {
          return this.form?.pushes[index - 1].title
        }
      }
    }
    return ''
  }

  getStartSettingType(type) {
    if (type === STARTING_POINT_TYPE.SCENARIO_START) {
      return 'シナリオ開始'
    } else if (type === STARTING_POINT_TYPE.SCENARIO_COMPLETED) {
      return 'ゴール到達'
    }
    return ''
  }

  openStartSettings(index) {
    this.$emit('openStartSettings', index)
  }
}
