
import { mixins, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IInitialCampaign, IPushCampaign, ISchedule } from '@/utils/types'
import PushCampaignMixin from '@/components/push-campaign/mixins/PushCampaignMixin.vue'
import { STARTING_POINT_TYPE } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue', 'update:onChangeStartingPoint'],
})
export default class ScheduleSetting extends mixins(PushCampaignMixin) {
  @Prop({
    default: {
      schedule_type: '',
      schedule_by_type: '',
      delay_day: 0,
      day_of_week: 0,
      day_of_month: 1,
      date: '',
      time: '',
    },
  })
  modelValue!: ISchedule

  @Prop()
  startingPoint!: string

  @Prop()
  previousCampaign!: IPushCampaign | IInitialCampaign

  type = ''

  get startingPointTypeList() {
    const options = [
      {
        id: STARTING_POINT_TYPE.SCENARIO_START,
        // title: this.$t('label.push.one_time'),
        title: this.$t('label.story.scenario_start_time'),
      },
      {
        id: STARTING_POINT_TYPE.SCENARIO_COMPLETED,
        // title: this.$t('label.push.one_time'),
        title: this.$t('label.story.scenario_goal_arrival_time'),
      },
    ]
    return options
  }

  get requiredRule() {
    const requiredRule = {
      delayDays: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.push.delay_days'),
      }),
      time: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.push.time'),
      }),
    }
    return requiredRule
  }

  get value() {
    return this.modelValue
  }

  set value(value: ISchedule) {
    this.$emit('update:modelValue', value)
  }

  @Watch('type', { immediate: true })
  async onChangeStartingPoint() {
    this.$emit('update:onChangeStartingPoint', this.type)
  }

  created() {
    this.type = this.startingPoint
    if (this.value.schedule_by_type === '') {
      this.value.schedule_by_type = 'by_delay_date'
    }

    if (this.value.schedule_type === '') {
      this.value.schedule_type = 'multi_time'
    }
  }
}
