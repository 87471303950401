<template>
  <div class="row">
    <div
      v-for="(item, index) in popupList"
      :key="index"
      @click="onRowClick(item)"
      class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6"
    >
      <div
        :style="{
          backgroundImage: `url(${item.images[0].pc.img_thumb})`,
          backgroundSize: 'cover',
        }"
        class="square radius-4 q-ma-xs"
        :class="[item._id == value._id ? 'item-selected' : '', item.is_active ? 'item-active' : 'dotted-border']"
      >
        <span v-if="item.is_active" class="text-white content">{{ $t('label.story.in_use') }}</span>
        <q-tooltip> {{ item.popup_name }}</q-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IPopup } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class PopupGridView extends Vue {
  @Prop()
  modelValue!: IPopup

  @Prop()
  popupList!: IPopup[]

  get value() {
    return this.modelValue
  }

  set value(value: IPopup) {
    this.$emit('update:modelValue', value)
  }

  onRowClick(item: IPopup) {
    this.value = item
  }
}
</script>

<style scoped lang="scss">
:deep(.row-selected .dotted-border) {
  border: none;
}
.item-active {
  position: relative;
  align-content: center;
  text-align: center;
}
.item-active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.8);
  z-index: 1;
  border-radius: 4px;
}
.item-selected.item-active::before {
  border-radius: 0;
}
.content {
  position: relative;
  z-index: 2;
}
</style>
