import { render } from "./PopupGridView.vue?vue&type=template&id=c67804da&scoped=true"
import script from "./PopupGridView.vue?vue&type=script&lang=ts"
export * from "./PopupGridView.vue?vue&type=script&lang=ts"

import "./PopupGridView.vue?vue&type=style&index=0&id=c67804da&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-c67804da"

export default script
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTooltip});
