<template>
  <div class="flex justify-center">
    <q-btn
      size="8px"
      round
      flat
      class="start-setting-btn"
      :class="detail?.schedule?.delay_day && detail?.schedule?.time ? 'active' : ''"
      icon="bolt"
    >
      <q-menu
        persistent
        :offset="[-30, -40]"
        class="start-settings"
        :class="detail?.schedule?.delay_day && detail?.schedule?.time ? 'active' : ''"
      >
        <q-list>
          <q-item>
            <q-item-section avatar>
              <q-icon name="bolt" style="color: #f29600; font-size: 1.6em" />
            </q-item-section>
            <q-item-section style="font-size: 12px">
              <div class="flex justify-between items-center">
                <div class="q-mr-sm q-px-sm q-py-xm bg-grey-3 text-center" style="min-width: 156px">
                  <span>{{ getpreviousCampaignName(index) }}</span>
                </div>
                <div>の</div>
              </div>
              <div class="flex justify-between items-center q-mt-sm">
                <div class="q-mr-sm q-px-sm q-py-xm bg-grey-3 text-center" style="min-width: 106px">
                  <span>{{ getStartSettingType(detail?.starting_point) }}</span>
                </div>
                <div>から</div>
              </div>
              <div class="flex justify-between items-center q-mt-sm">
                <div class="q-mr-sm q-px-sm q-py-xm bg-grey-3">
                  {{ detail?.schedule?.delay_day ?? 0 }}
                </div>
                <div>日後の</div>
                <div class="q-mx-sm q-px-sm q-py-xm bg-grey-3 text-center" style="min-width: 76px">
                  <span>{{ detail?.schedule?.time ? detail?.schedule?.time : '--:--' }}</span>
                </div>
                <div>に配信</div>
              </div>
            </q-item-section>
          </q-item>
          <q-item style="padding-top: 0">
            <q-item-section>
              <span style="font-size: 8px">＊設定変更、回答による対象・除外設定は、</span>
            </q-item-section>
            <q-item-section side>
              <q-btn
                size="xs"
                label="スタート設定"
                class="btn-save btn-open-start-setting"
                @click="openStartSettings(index)"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IPushCampaignDetail, IStory } from '@/utils/types'
import { STARTING_POINT_TYPE, TRIGGER_TYPE } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: ['openStartSettings'],
})
export default class StartSettingBtnSelector extends Vue {
  @Prop()
  detail!: IPushCampaignDetail

  @Prop()
  form!: IStory

  @Prop()
  index!: number

  getpreviousCampaignName(index) {
    if (this.form.trigger_type === TRIGGER_TYPE.POPUP_DELIVER) {
      if (index === 0) {
        return this.form?.initial?.title
      } else {
        if (this.form?.pushes) {
          return this.form?.pushes[index - 1].title
        }
      }
    }
    return ''
  }

  getStartSettingType(type) {
    if (type === STARTING_POINT_TYPE.SCENARIO_START) {
      return 'シナリオ開始'
    } else if (type === STARTING_POINT_TYPE.SCENARIO_COMPLETED) {
      return 'ゴール到達'
    }
    return ''
  }

  openStartSettings(index) {
    this.$emit('openStartSettings', index)
  }
}
</script>
<style scoped lang='scss'>
.btn-open-start-setting {
  padding: 2px 6px;
  :deep(span) {
    font-weight: unset;
    line-height: unset;
    font-size: unset;
    letter-spacing: unset;
  }
}

.start-setting-btn {
  background: #d9d9d9;
  color: #f29600;
}
.start-setting-btn.active {
  background: #f5d882;
  color: #f29600;
}
:deep(.q-btn .q-icon) {
  font-size: 1.5em;
}
:deep(.q-item__section--main ~ .q-item__section--side) {
  padding-left: 4px;
}
:deep(.q-item) {
  min-height: auto;
}
</style>
