import { render } from "./StartSettingBtnSelector.vue?vue&type=template&id=8f52138e&scoped=true"
import script from "./StartSettingBtnSelector.vue?vue&type=script&lang=ts"
export * from "./StartSettingBtnSelector.vue?vue&type=script&lang=ts"

import "./StartSettingBtnSelector.vue?vue&type=style&index=0&id=8f52138e&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-8f52138e"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QMenu,QList,QItem,QItemSection,QIcon});
