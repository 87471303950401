
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IPopup } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class PopupGridView extends Vue {
  @Prop()
  modelValue!: IPopup

  @Prop()
  popupList!: IPopup[]

  get value() {
    return this.modelValue
  }

  set value(value: IPopup) {
    this.$emit('update:modelValue', value)
  }

  onRowClick(item: IPopup) {
    this.value = item
  }
}
