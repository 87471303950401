import { render } from "./FrequencyCapSetting.vue?vue&type=template&id=05b2343b&scoped=true"
import script from "./FrequencyCapSetting.vue?vue&type=script&lang=ts"
export * from "./FrequencyCapSetting.vue?vue&type=script&lang=ts"

import "./FrequencyCapSetting.vue?vue&type=style&index=0&id=05b2343b&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-05b2343b"

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QToggle,QInput,QField});
