<template>
  <div>
    <q-list dense style="width: 350px" class="q-mt-sm">
      <q-item>
        <q-item-section side>
          <div class="q-gutter-xs">
            <q-toggle round v-model="value.active" val="value.active" ref="active" />
          </div>
        </q-item-section>
        <q-item-section>
          <q-input
            v-model="value.time_per_user"
            dense
            outlined
            type="number"
            class="text-h6"
            min="1"
            :rules="[
              (val) => (val !== null && val !== '') || !value.active || requiredRule.frequencyCap,
              (val) => val > 0 || !value.active || requiredRule.minFrequencyCap,
            ]"
          />
        </q-item-section>
        <q-item-section side>
          <div class="q-gutter-xs">{{ $t('label.push.times_per_user') }}</div>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IFrequency } from '@/utils/types'
import PushCampaignMixin from '@/components/push-campaign/mixins/PushCampaignMixin.vue'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class FrequencyCapSetting extends mixins(PushCampaignMixin) {
  @Prop({
    default: {
      active: false,
      time_per_user: 1,
    },
  })
  modelValue!: IFrequency

  get value() {
    return this.modelValue
  }

  set value(value: IFrequency) {
    this.$emit('update:modelValue', value)
  }

  get requiredRule() {
    const requiredRule = {
      frequencyCap: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.push.frequency_cap'),
      }),
      minFrequencyCap: this.$t('validate.must_be_greater_than_0'),
    }
    return requiredRule
  }
}
</script>

<style scoped lang="scss">
.q-field--with-bottom {
  padding-bottom: 0;
}
</style>
