import { render } from "./AudienceBuilder.vue?vue&type=template&id=73aae422&scoped=true"
import script from "./AudienceBuilder.vue?vue&type=script&lang=ts"
export * from "./AudienceBuilder.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-73aae422"

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm});
