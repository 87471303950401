
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IFrequency } from '@/utils/types'
import PushCampaignMixin from '@/components/push-campaign/mixins/PushCampaignMixin.vue'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class FrequencyCapSetting extends mixins(PushCampaignMixin) {
  @Prop({
    default: {
      active: false,
      time_per_user: 1,
    },
  })
  modelValue!: IFrequency

  get value() {
    return this.modelValue
  }

  set value(value: IFrequency) {
    this.$emit('update:modelValue', value)
  }

  get requiredRule() {
    const requiredRule = {
      frequencyCap: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.push.frequency_cap'),
      }),
      minFrequencyCap: this.$t('validate.must_be_greater_than_0'),
    }
    return requiredRule
  }
}
