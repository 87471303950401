<template>
  <div class="row q-mt-md">
    <div class="col-5">
      <q-list dense separator>
        <q-toolbar class="bg-green text-white shadow-2">
          <q-toolbar-title class="q-ml-md">{{ $t('label.story.eligible') }}</q-toolbar-title>
        </q-toolbar>
        <q-item clickable v-ripple v-for="(item, index) in reaches" :key="index">
          <q-item-section class="q-ml-md">
            <q-item-label>
              <q-icon :name="iconName(item)" :style="iconBg(item)" color="white" class="sgbmk-icon" size="xs" />
              {{ item.title.length > 20 ? item.title.slice(0, 20) + '...' : item.title }}
              <q-tooltip>{{ item.title }}</q-tooltip>
            </q-item-label>
            <q-item-label class="sub-title">サイズ: {{ item.totalUsers }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <div class="row q-gutter-sm">
              <q-btn
                size="8px"
                flat
                round
                color="white"
                text-color="purple"
                icon="add"
                class="dotted-border"
                @click="onAdd(item, index)"
                style="border-color: #9c27b0"
              ></q-btn>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
    <div class="col-2 q-my-auto">
      <div class="q-pa-md q-gutter-sm q-ma-md text-center">
        <q-icon name="swap_horiz" color="grey" size="32px" />
      </div>
    </div>
    <div class="col-5">
      <q-list dense separator>
        <q-toolbar class="bg-purple text-white shadow-2">
          <q-toolbar-title class="q-ml-md">{{ $t('label.story.not_eligible') }}</q-toolbar-title>
        </q-toolbar>
        <q-item clickable v-ripple v-for="(item, index) in unReached" :key="index">
          <q-item-section class="q-ml-md">
            <q-item-label>
              <q-icon :name="iconName(item)" :style="iconBg(item)" color="white" class="sgbmk-icon" size="xs" />
              {{ item.title.length > 20 ? item.title.slice(0, 20) + '...' : item.title }}
              <q-tooltip>{{ item.title }}</q-tooltip>
            </q-item-label>
            <q-item-label class="sub-title">サイズ: {{ item.totalUsers }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-btn
              size="8px"
              flat
              round
              color="white"
              text-color="primary"
              icon="undo"
              class="dotted-border"
              @click="onUnSelected(item, index)"
              style="border-color: #4956e3"
            ></q-btn>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
  <div class="horizontal-line"></div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { EScenarioResourceType, IPushAudience, IUserAnswer } from '@/utils/types'
import PushCampaignMixin from '@/components/push-campaign/mixins/PushCampaignMixin.vue'
import { ICard } from 'bot-flow-maker/src/types'
import { USER_ANSWER } from '@/store/actions'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class UnReachedUsersSetting extends mixins(PushCampaignMixin) {
  @Prop({})
  modelValue: IPushAudience = {
    unreached_users: [],
  }

  @Prop({})
  isTestMode!: boolean

  @Prop({})
  campaignId!: string

  @Prop()
  scenarios!: ICard[]

  reaches: ICard[] = []
  unReached: ICard[] = []

  @Watch('value', { immediate: true })
  async onRefresh() {
    this.unReached = this.value.unreached_users ?? []
    this.updateApi()
  }

  get value() {
    return this.modelValue
  }

  set value(value: IPushAudience) {
    this.$emit('update:modelValue', value)
  }

  iconName(row: ICard) {
    if (row.cardType === 'question') {
      return 'help_outline'
    } else if (row.cardType === 'goal') {
      return 'verified'
    }
    return 'message'
  }

  iconBg(row: ICard) {
    if (row.cardType === 'question') {
      return 'background: #ffc10d'
    } else if (row.cardType === 'goal') {
      return 'background: #6e9c9f'
    }
    return 'background: #8f969e'
  }

  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }

    const usersAnswers: IUserAnswer[] = await this.$store.dispatch(USER_ANSWER.GET_USER_ANSWER_BY_CAMPAIGN, {
      app_id: this.selectedAppId,
      campaign_id: this.campaignId,
      is_test_mode: this.isTestMode,
    })

    this.reaches = []
    if (this.scenarios) {
      const cards = this.scenarios.filter(
        (item) =>
          item.cardType === EScenarioResourceType.message ||
          item.cardType === EScenarioResourceType.question ||
          item.cardType === EScenarioResourceType.goal
      )
      for (let index = 0; index < cards.length; index++) {
        const item = cards[index]
        if (!this.checkExistItem(item.id)) {
          const a = usersAnswers.filter((p) => p.next_unique_id === item.uniqueId)
          const uniqueUserIds = new Set(a.map((p) => p.user_id)) // Create a set of unique user IDs
          const uniqueUserIdCount = uniqueUserIds.size // Count of unique user IDs
          item.totalUsers = uniqueUserIdCount
          this.reaches.push(item)
        }
      }

      this.value.reached_users = this.reaches
    }
  }

  checkExistItem(id: string) {
    const checkExist = this.value?.unreached_users?.find((item) => item.id === id)
    if (checkExist) {
      return true
    }
    return false
  }

  onAdd(row: ICard, index: number) {
    if (!this.value.unreached_users) {
      this.value.unreached_users = []
    }
    if (this.value.reached_users) {
      this.value.reached_users.splice(index, 1)
      this.value.unreached_users.push(row)
      this.onRefresh()
    }
  }

  onUnSelected(row: ICard, index: number) {
    if (!this.value.reached_users) {
      this.value.reached_users = []
    }
    if (this.value.unreached_users) {
      this.value.unreached_users.splice(index, 1)
      this.value.reached_users.push(row)
      this.onRefresh()
    }
  }
}
</script>
<style lang="scss" scoped>
.sgbmk-icon {
  padding: 4px;
  border: 1px solid;
  border-radius: 6px;
}

.horizontal-dotted-line {
  border-bottom: 1px dotted grey;
}
.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 23.17px;
  text-align: left;
}
.sub-title {
  font-size: 8px !important;
  font-weight: 400 !important;
  line-height: 8.69px !important;
  text-align: left;
  color: #8f969e;
}
.q-toolbar {
  min-height: 36px;
}
.q-toolbar__title {
  font-size: 18px;
}
:deep(.q-btn.dotted-border .q-icon) {
  font-size: 1.25em;
}
</style>
