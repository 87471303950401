<template>
  <div class="tagcode" id="tabcode" @click="copy" v-if="trackingCode">
    <div class="">
      <span class="has-text-grey">{{ $t('label.tab_location_desc') }}</span>
    </div>
    <div class="row q-mb-sm">
      <q-btn dense unelevated v-if="trackingCode" @click="copy" class="col q-mr-sm btn-save">
        {{ $t('label.story.copy_tags') }}
      </q-btn>
      <q-btn dense unelevated @click="downloadTagCode" class="col q-ml-sm btn-save">
        {{ $t('label.story.download_tags') }}
      </q-btn>
    </div>
    <div class="is-clearfix"></div>
    <pre v-highlightjs="tagCode" class="q-mt-none"><code class="javascript"></code></pre>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class TagCode extends Vue {
  // Props
  @Prop()
  trackingCode!: string

  @Prop()
  tagJsUrl!: string

  get varName() {
    return '_camach_line_ac'
  }

  get tagCode(): string {
    /* eslint-disable no-useless-escape */
    return `<script type="text/javascript">
  window.${this.varName} = [];${this.varName}.push(["setAccount", "${this.trackingCode}"]);var _protocol = (("https:" == document.location.protocol) ? "https://" : "http://"); (function() {var atag = document.createElement("script");atag.type = "text/javascript"; atag.async = true;atag.src = _protocol + "${this.tagJsUrl}";var s = document.getElementsByTagName("script")[0];s.parentNode.insertBefore(atag, s);})();
<\/script>`
  }

  tagCopySuccess(): void {
    this.$q.notify({
      message: this.$t('label.copied'),
      color: 'positive',
      badgeStyle: 'opacity: 0',
    })
  }

  downloadTagCode() {
    const html = this.$t('label.comment_txt_file_download_tag') + this.tagCode
    const blob = new Blob([html], { type: 'text/plain' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'CV tag.txt'
    link.click()
  }

  copy(): void {
    const myFluffyTextarea = document.createElement('textarea')

    myFluffyTextarea.innerHTML = this.tagCode

    const parentElement = document.getElementById('tabcode')
    if (!parentElement) {
      return
    }

    parentElement.appendChild(myFluffyTextarea)
    myFluffyTextarea.select()

    document.execCommand('copy')

    parentElement.removeChild(myFluffyTextarea)
    this.tagCopySuccess()
  }
}
</script>

<style lang="sass" scoped>
.csv-position
  margin-top: -40px
  float: right
  margin-right: 2px
</style>
