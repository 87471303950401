import { render } from "./StorySettingForm.vue?vue&type=template&id=6bff98e4"
import script from "./StorySettingForm.vue?vue&type=script&lang=ts"
export * from "./StorySettingForm.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QInput,QSpace,QBtn});
