<template>
  <CLoading :loading="loading" />
  <div class="row" style="height: 100%">
    <div
      class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12"
      style="border-right: 1px solid #d0d2e0; overflow-y: auto"
    >
      <div class="flex justify-between" style="border-bottom: 1px solid #d0d2e0">
        <div class="text-h5 q-ml-md q-pa-sm" style="border-bottom: 1px solid black">
          {{ $t('label.story.audience_settings') }}
        </div>
      </div>
      <div class="q-ma-md">
        <q-form ref="formRef" greedy>
          <div class="q-my-md" v-if="isTestMode">
            <AudienceOrNotSetting
              v-model="form.test_detail.audience"
              :isTestMode="isTestMode"
              @update:handleLoading="handleLoading"
              ref="audienceOrNotTest"
            />
          </div>
          <div class="q-my-md" v-if="!isTestMode">
            <AudienceOrNotSetting
              v-model="form.prod_detail.audience"
              :isTestMode="isTestMode"
              @update:handleLoading="handleLoading"
              ref="audienceOrNotProd"
            />
          </div>
        </q-form>
      </div>
    </div>
    <div
      class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12"
      style="overflow-y: auto; max-height: calc(100vh - 200px)"
    >
      <div class="flex justify-between" style="border-bottom: 1px solid #d0d2e0">
        <div class="text-h5 q-ml-md q-pa-sm" style="border-bottom: 1px solid black">
          {{ $t('label.story.advanced_setting') }}
        </div>
        <div style="width: 10px"></div>
      </div>
      <div class="q-ml-md q-mt-sm">
        <q-form ref="pushForm" greedy>
          <div class="flex items-center">
            <div class="text-subtitle1 text-grey-8 q-mr-md text-bold">{{ $t('label.push.frequency_cap') }}</div>
            <div class="horizontal-line" style="flex: 1"></div>
          </div>
          <FrequencyCapSetting v-model="form.prod_detail.frequency" />
          <div class="flex items-center">
            <div class="text-subtitle1 text-grey-8 q-mr-md text-bold">{{ $t('label.push.schedule') }}</div>
            <div class="horizontal-line" style="flex: 1"></div>
          </div>
          <PushScheduleSetting v-model="form.prod_detail.schedule" />
        </q-form>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { IPushCampaign, IStory } from '@/utils/types'
import { maska } from 'maska'
import AudienceOrNotSetting from '@/components/story/common/selectors/AudienceOrNotSetting.vue'
import cloneDeep from 'lodash/cloneDeep'
import CLoading from '@/components/common/ui/CLoading.vue'
import FrequencyCapSetting from '@/components/story/common/selectors/FrequencyCapSetting.vue'
import PushScheduleSetting from '@/components/story/common/selectors/PushScheduleSetting.vue'

@Options({
  components: { PushScheduleSetting, FrequencyCapSetting, CLoading, AudienceOrNotSetting },
  directives: { maska },
  emits: ['update:modelValue', 'update:onSave', 'update:onNextStep'],
})
export default class AudienceBuilder extends Vue {
  @Prop({})
  modelValue!: IStory

  @Prop({})
  isTestMode!: boolean

  form: IPushCampaign = {
    _id: '',
    title: '',
    app_id: '',
    delivered: 0,
    is_active: false,
    is_test_mode: false,
    audience_size: 0,
    prod_detail: {
      direction_type: 1,
      audience: {
        audience_or: [],
        audience_not: [],
      },

      frequency: {
        active: false,
        time_per_user: 1,
      },
      schedule: {
        schedule_type: '',
        schedule_by_type: '',
        delay_day: 0,
        day_of_week: 0,
        day_of_month: 1,
        date: '',
        time: '',
      },
      scenarios: [],
    },
    test_detail: {
      direction_type: 1,
      audience: {
        audience_or: [],
        audience_not: [],
      },

      frequency: {
        active: false,
        time_per_user: 1,
      },
      schedule: {
        schedule_type: '',
        schedule_by_type: '',
        delay_day: 0,
        day_of_week: 0,
        day_of_month: 1,
        date: '',
        time: '',
      },
      scenarios: [],
    },
  }

  loading = false

  get story() {
    return this.modelValue
  }

  set story(value: IStory) {
    this.$emit('update:modelValue', value)
  }

  @Watch('isTestMode', { immediate: true })
  async onChangeTestMode() {
    if (this.story.push) {
      this.form = cloneDeep(this.story.push)
    }
  }

  validate(isSave = false) {
    this.onSubmit(isSave)
  }

  async onSubmit(isSave = false) {
    this.form.is_test_mode = this.story.is_test_mode
    if (this.story.pushes) {
      this.story.pushes[0] = this.form
    }

    if (isSave) {
      this.$emit('update:onSave')
    } else {
      this.$emit('update:onNextStep')
    }
  }

  handleLoading(val) {
    this.loading = val
  }

  created() {
    this.onChangeTestMode()
  }
}
</script>

<style scoped lang='scss'>
</style>
