
import { mixins, Options } from 'vue-class-component'
import { IInitialCampaign, IStory, IValidRequest } from '@/utils/types'
import ScenariosMaker from '@/components/story/scenarios/ScenariosMaker.vue'
import { ICard } from 'bot-flow-maker/src/types'
import { Prop, Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { maska } from 'maska'
import { ACTION_APP } from '@/store/actions'
import InitialCampaignMixin from '@/components/initial-campaign/mixins/InitialCampaignMixin.vue'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'

@Options({
  components: { ScenariosMaker },
  directives: { maska },
  emits: ['update:modelValue', 'update:onSave', 'update:onNextStep'],
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    this.isCancel = false
    return await this.checkDraftValue()
  },
})
export default class Initial extends mixins(InitialCampaignMixin, BaseFormMixin) {
  @Prop({})
  modelValue!: IStory

  @Prop({})
  isTestMode!: boolean

  @Prop({})
  isCopy!: boolean

  isUseInitial = true

  scenarios: ICard[] = []
  directionType = '0'
  componentKey = 0
  isMounted = false
  form: IInitialCampaign = {
    _id: '',
    app_id: this.story.app_id,
    title: this.story.story_name,
    delivered: 0,
    is_active: false,
    is_test_mode: false,
    prod_detail: {
      is_use: true,
      direction_type: 0,
      welcomes: [
        {
          name: 'welcome message',
          order: 1,
          fixed: false,
        },
      ],
      init_quick_reply: {
        is_auto_start: true,
        start_survey: '',
        is_start_survey: false,
        restart_survey: '',
        is_restart_survey: false,
      },
      scenarios: [],
    },
    test_detail: {
      is_use: true,
      direction_type: 0,
      welcomes: [
        {
          name: 'welcome message',
          order: 1,
          fixed: false,
        },
      ],
      init_quick_reply: {
        is_auto_start: true,
        start_survey: '',
        is_start_survey: false,
        restart_survey: '',
        is_restart_survey: false,
      },
      scenarios: [],
    },
    story_id: this.story._id,
  }

  get story() {
    return this.modelValue
  }

  set story(value: IStory) {
    this.$emit('update:modelValue', value)
  }

  @Watch('isTestMode', { immediate: true })
  async onChangeTestMode() {
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.scenarios = this.form.test_detail.scenarios ?? []
        this.directionType = this.form.test_detail.direction_type?.toString() ?? '0'
        this.isUseInitial = this.form.test_detail?.is_use ?? true
      }
    } else {
      if (this.form.prod_detail) {
        this.scenarios = this.form.prod_detail.scenarios ?? []
        this.directionType = this.form.prod_detail.direction_type?.toString() ?? '0'
        this.isUseInitial = this.form.prod_detail?.is_use ?? true
      }
    }

    this.componentKey = this.componentKey + 1
  }

  onChangeDirection(value) {
    this.directionType = value
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.form.test_detail.direction_type = Number.parseInt(value)
      }
    } else {
      if (this.form.prod_detail) {
        this.form.prod_detail.direction_type = Number.parseInt(value)
      }
    }
  }

  onChangeScenarios(value) {
    this.scenarios = value
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.form.test_detail.scenarios = value
      }
    } else {
      if (this.form.prod_detail) {
        this.form.prod_detail.scenarios = value
      }
    }
    this.story.initial = this.form
  }

  validate(isSave = false) {
    this.onSubmit(isSave)
  }

  async checkSizeCardsOfScenarios() {
    if (this.isNew) {
      return true
    }
    const { dispatch } = this.$store
    const params: IValidRequest = {
      app_id: this.form.app_id ?? '',
      initial_id: this.form._id ?? '',
      push_id: '',
      card_id: '',
      card_type: '',
      total_messages: 0,
      campaign: this.form,
    }
    const resp = await dispatch(ACTION_APP.CHECK_LIMIT_CARDS, params)
    if (resp.count > 5) {
      const card = resp.card
      this.$refs.scenarioEditor.handleErrorCard(card.uniqueId)
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.size_must_be_between_1_and_5', {
          card_title: card.title,
          count: resp.count,
          campaign_type: resp.campaign_type,
          campaign_title: resp.campaign_title,
        }),
        persistent: true,
        html: true,
      })
      return false
    }
    return true
  }

  async onSubmit(isSave = false) {
    if (!(await this.checkSizeCardsOfScenarios())) {
      return
    }
    this.story.initial = this.form
    if (isSave) {
      this.$emit('update:onSave')
    } else {
      this.$emit('update:onNextStep')
    }
  }

  @Watch('isUseInitial', { immediate: true })
  async onIsUseInitialChange() {
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.form.test_detail.is_use = this.isUseInitial
      }
    } else {
      if (this.form.prod_detail) {
        this.form.prod_detail.is_use = this.isUseInitial
      }
    }
  }

  mounted() {
    if (this.story.initial?._id) {
      this.form = { ...this.form, ...cloneDeep(this.story.initial) }
      this.onChangeTestMode()
    }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
    this.isMounted = true
  }
}
